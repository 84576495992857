export const config = {
    RPC_URL: 'https://rpc-testnet.yumzee.wtf',
    REST_URL: 'https://api-testnet.yumzee.wtf',
    EXPLORER_URL: 'https://explorer.yumzee.wtf/yumzee',
    NETWORK_NAME: 'Yumzee',
    NETWORK_TYPE: 'testnet',
    CHAIN_ID: 'yumzee-testnet-1',
    CHAIN_NAME: 'Yumzee',
    COIN_DENOM: 'yumzee',
    COIN_MINIMAL_DENOM: 'yumzee',
    COIN_DECIMALS: 0,
    PREFIX: 'yumzee',
    COIN_TYPE: 999,
    COINGECKO_ID: 'yumzee',
    DEFAULT_GAS: 250000,
    GAS_PRICE_STEP_LOW: 0.0,
    GAS_PRICE_STEP_AVERAGE: 0.0,
    GAS_PRICE_STEP_HIGH: 0.025,
    FEATURES: ['ibc-transfer', 'no-legacy-stdTx'],
};
